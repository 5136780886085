.modalstyle .modalheader {
  border-bottom: none;
}

.modalstyle .modalbody {
  border-bottom: none;
}

.modalstyle [class="modal-content"] {
  /* background: var(--violet_clr2); */
  background-color: var(--whitetext);
  /* box-shadow: var(--themecolor) -1px 0px 14px; */
  /* border: 1px solid var(--themecolor); */
  /* min-width: 320px; */
  max-width: 320px;
  margin: auto;
  border-radius: 30px;
  width: 100%;
  /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter {
  padding: 20px;
  border-top: 0px;
}
.modalstyle .btnstyle {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 5px;
  height: 40px;
  position: relative;
}

.modalstyle .btnstyle:hover {
  background: transparent !important;
  border: 1px solid var(--themecolor) !important;
  color: var(--themecolor) !important;
  transition: none 0s ease 0s !important;
}
.modalstyle .btnstyle::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 43%;
  transform: rotate(90deg);
}

.modalstyle .btnstyle::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 43%;
  transform: rotate(270deg);
}
.modalstyle .btnstyle:hover::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
}

.modalstyle .btnstyle:hover::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
}

.modalstyle .modalheader .modaltitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
  color: var(--themecolor);
}
.modalstyle .modalheader {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  background: var(--light_linear);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.modalstyle .modalheader .closebtn {
  background: none;
  padding: 0px;
  border: none;
  font-size: 25px;
  color: var(--themecolor);
  cursor: pointer;
}
.modalstyle .modalbody {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  padding: 40px 25px;
}
.modalstyle .modalbody .label {
  font-size: 12px;
  color: var(--themecolor);
}
.modalstyle .modalbody .walletimg {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.modalstyle .walletrow {
  width: 100px;
  height: 100px;
  padding: 10px;
}
.modalstyle .walletrow:hover .label {
  /* background: var(--themecolor); */
  color: var(--themecolor);
}
.modalstyle .walletrow:hover {
  opacity: 0.6;
  cursor: pointer;
}
.modalstyle .modalfooter .footerdesc {
  color: var(--blacktext);
  text-align: center;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 10px;
}
