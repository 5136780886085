.modalstyle .modalheader {
  border-bottom: none;
}

.modalstyle .modalbody {
  border-bottom: none;
}

.modalstyle [class="modal-content"] {
  /* background: var(--cardbg); */
  /* box-shadow: var(--themecolor) -1px 0px 14px; */
  /* border: 1px solid var(--themecolor); */
  min-width: 320px;
  max-width: 320px;
  margin: auto;
  border-radius: 30px;
  width: 100%;
  /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter {
  padding: 20px;
  border-top: 0px;
}
.modalstyle .btnstyle {
  background: var(--themecolor);
  border-radius: 7px;
  color: var(--whitetext);
  font-size: 12px;
  border: 1px solid var(--themecolor);
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
}

.modalstyle .btnstyle:hover {
  background: var(--light_violet_clr2) !important;
  border: 1px solid var(--light_violet_clr2) !important;
  color: var(--themecolor) !important;
}
.modalstyle .modalheader .modaltitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
  color: var(--themecolor);
}
.modalstyle .modalheader {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  background: var(--light_linear);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.modalstyle .modalheader .closebtn {
  background: none;
  padding: 0px;
  border: none;
  color: var(--themecolor);
  font-size: 20px;
}
.modalstyle .modalbody {
  /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
  padding: 40px 25px;
}

.clrsec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.translist {
  color: var(--whitetext);
  font-size: 15px;
  margin-bottom: 5px;
  text-decoration: none;
  background-color: transparent;
  border: none;
}
.translist svg {
  margin-left: 5px;
}
.translist:hover {
  color: var(--themecolor);
}
.translist:hover svg {
  fill: var(--themecolor);
}
.clrbtn {
  background: var(--themecolor);
  padding: 5px 10px;
  font-size: 14px;
  color: var(--whitetext);
}
.clrbtn:hover {
  background: var(--btnhover);
}
.label {
  margin-bottom: 0px;
  color: var(--whitetext);
}
@media screen and (min-width: 575px) {
  .modalstyle [class="modal-content"] {
    min-width: 400px;
    max-width: 400px;
  }
}

@media screen and (max-width: 400px) {
  .modalstyle [class="modal-content"] {
    min-width: 280px;
    max-width: 280px;
  }
}
