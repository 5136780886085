.header {
  background: transparent;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  width: 100% !important;
  /* padding-right: 5% !important; */
  margin: 0px auto !important;
  position: relative !important;
  height: 77px !important;
  /* padding-left: 5%; */
}

.header .headerrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .logoimg {
  width: 85px;
}

/* .header .customcontainer {
 max-width: 100%;
} */

.header .navsec {
  list-style-type: none;
  display: flex;
  gap: 6px;
  align-items: center;
  padding-left: 0px;
}

.header .navsec li {
  color: var(--lightgrey);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.header .dropbtn {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--themecolor);
  background: none !important;
  border: none;
  width: 100%;
}

.header .dropbtn:hover {
  background: none;
}

.header .dropdown {
  position: relative;
  display: inline-block;

  padding: 10px;
}

.header .dropcontent {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  z-index: 1;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  /* bottom: 0px; */
  top: 43px;
  left: 0px;
  border-radius: 11px;
  z-index: 3;
  box-shadow: 0px 2px 2px #56525247;
}

.header .dropdown:hover {
  background-color: var(--themecolor);
  border-radius: 10px;
  color: var(--whitetext);
}

.header .dropdown:hover button {
  color: var(--whitetext) !important;
}

.mobileheader .dropcontent {
  bottom: 61px;
  top: unset;
}

.mobileheader .dropcontent a {
  font-size: 14px;
}

.header .dropdown:hover .dropcontent {
  display: block;
}

.header .dropcontent a {
  color: var(--themecolor);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.header .dropdown:hover .dropbtn {
  color: var(--themecolor);
}

.header .dropcontent a:hover {
  color: var(--whitetext);
  background: var(--themecolor);
}

.header .dropdown:hover .dropcontent {
  display: block;
}

.header .dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.header a {
  color: var(--lightgrey);
  text-decoration: none;
}

/* .header .rightside .connectwalletbtn {
    background: var(--themecolor);
    border-radius: 0px;
    height: 30px;
    border: 1px solid var(--themecolor);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

} */
.header .rightside .connectwalletbtn {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  padding-top: 5px;
  /* width: 100%; */
  min-width: 170px;
  padding-bottom: 5px;
  border-radius: 5px;
  height: 40px;
  position: relative;
}

/* .header .rightside .connectwalletbtn:hover {
    border: 1px solid var(--themecolor);
    background: none;
    color: var(--themecolor);
} */
.header .rightside .connectwalletbtn:hover {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--linearbtn);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  padding-top: 5px;
  /* width: 100%; */
  min-width: 170px;
  padding-bottom: 5px;
  border-radius: 5px;
  height: 40px;
  position: relative;
}
.header .rightside .connectwalletbtn::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 43%;
  transform: rotate(90deg);
}

.header .rightside .connectwalletbtn::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 43%;
  transform: rotate(270deg);
}

.header .rightside .connectwalletbtn:hover {
  background: transparent !important;
  border: 1px solid var(--themecolor) !important;
  color: var(--themecolor) !important;
  transition: none 0s ease 0s !important;
}

.header .rightside .connectwalletbtn:hover::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
}

.header .rightside .connectwalletbtn:hover::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
}

.header .rightside {
  display: flex;
  gap: 10px;
  align-items: center;
}

.header .rightside .settingicon {
  font-size: 25px;
}

.mobileheader {
  background: var(--whitetext);
  border: 1px solid var(--themecolor);
  /* padding: 10px; */
  /* height: 77px; */
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 10;
  bottom: 0px;
  left: 0px;
  padding-top: 5px;
  padding-right: 8px;
  padding-left: 8px;
  /* border-top: 1px solid var(--themecolor); */
}

.mobileheader a {
  color: var(--lightgrey);
  text-decoration: none;
}

.mobileheader .menurow {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.mobileheader .menuimg {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-bottom: 3px;
}

.mobileheader .imgrow {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
}
.mobileheader .imgrow svg {
  fill: var(--themecolor) !important;
}
.header .dropdown:hover svg {
  fill: var(--whitetext) !important;
}
.mobileheader a,
.mobileheader .dropbtn {
  font-size: 10px;
  color: var(--themecolor);
}

.mobileheader .dropdown {
  padding: 3px 10px;
}

.header .headerrow .leftsec {
  display: flex;
  gap: 20px;
  align-items: center;
}

.header .dropcontent.lastmenu {
  left: unset;
  right: 0px;
}

.header .networksec .netimg {
  width: 20px;
  height: 20px;
}

.header .networksec .networkbtn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  max-width: 185px;
  border: none;
  height: 40px;
  border-radius: 5px;
  justify-content: center;
  min-width: 85px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  background: var(--linearbtn);
  position: relative;
}
.header .networksec .networkbtn::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 43%;
  transform: rotate(90deg);
}

.header .networksec .networkbtn::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 43%;
  transform: rotate(270deg);
}
.header .networksec .networkbtn:hover::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
}

.header .networksec .networkbtn:hover::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
}

.header .networksec .networkbtn:hover {
  background: transparent;
  color: var(--themecolor);
}

.header .headdrop {
  position: relative;
  display: inline-block;
}

.header .headdrop .dropbtns {
  background: var(--themecolor) !important;
  color: white;
  font-size: 16px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 30px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 0px inset; */
  cursor: pointer;
  display: inline-flex;
  height: 32px;
  padding-left: 45px;
  padding-right: 8px;
  position: relative;
}
.header .headdrop .btnsec {
  position: relative;
}

.header .headdrop .btnsec svg {
  -webkit-box-align: center;
  align-items: center;
  background: var(--themecolor);
  border: 2px solid var(--dark_violet_clr);
  border-radius: 50%;
  display: flex;
  height: 40px;
  -webkit-box-pack: center;
  justify-content: center;
  left: 0px;
  position: absolute;
  top: -4px;
  width: 40px;
  z-index: 102;
  fill: #fff;
  padding: 5px;
}
.header .headdrop .btnsec .arrowicon {
  position: relative;
  background: none;
  border: none;
  padding: 0px;
  font-size: 12px;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  margin-right: 5px;

  top: 0px;
}
.header .headdrop .dropcontentstyle {
  display: none;
  position: absolute;
  background-color: var(--violet_clr2);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: var(--cardbg);
  border: 1px solid transparent;
  border-radius: 11px;
  padding-bottom: 4px;
  padding-top: 4px;
  pointer-events: auto;
  width: 280px;
  z-index: 99;
  padding: 13px;
  top: 34px;
  right: 0px !important;
  box-shadow: 0px 2px 2px #56525247;
}

.header .headdrop:hover .dropcontentstyle {
  display: block;
  background: var(--violet_clr2);
}
.header .headdrop:hover .dropcontentstyle ul {
  padding-left: 0px;
  display: flex;
  gap: 25px;
  flex-direction: column;
}
.header .headdrop:hover .dropcontentstyle ul li {
  color: var(--blacktext);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .headdrop:hover .dropcontentstyle ul li:hover {
  color: var(--themecolor);
  cursor: pointer;
}
.header .headdrop:hover .dropcontentstyle ul li:hover svg {
  fill: var(--themecolor);
}
@media screen and (max-width: 360px) {
  .header .logoimg {
    width: 70px !important;
  }
}

@media screen and (min-width: 361px) and (max-width: 576px) {
  .header .logoimg {
    width: 85px !important;
  }
}

@media screen and (max-width: 576px) {
  .mobileheader .dropcontentone {
    min-width: 80vw;
    left: 0;
    right: unset;
  }
  .mobileheader .dropcontenttwo {
    min-width: 80vw;
    left: -60px;
    right: unset;
  }
  .mobileheader .dropcontentthree {
    min-width: 80vw;
    right: -90px;
    left: unset;
  }

  .mobileheader .dropcontentfour {
    min-width: 80vw;
    right: 0px !important;
    left: unset !important;
  }
  .header .dropcontent a {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .hidemble {
    display: none;
  }
  .dropbtnstext {
    display: none;
  }
  .header .headdrop .dropbtns {
    padding-left: 40px;
    padding-right: 5px;
  }
  .mobileheader {
    display: block;
  }

  .header .rightside .connectwalletbtn {
    font-size: 12px;
  }

  .header .logoimg {
    width: 160px;
    /* margin-left: -14px; */
  }
}

@media screen and (min-width: 577px) {
  .header .dropcontent {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .dropcontentfor {
    right: -100px !important;
    left: unset !important;
  }
  .header .dropcontent {
    min-width: 280px;
  }
  .hidemble {
    display: block;
  }

  .mobileheader {
    display: none;
  }

  /* .header {
        padding-left: 3%;
    } */
}

@media screen and (min-width: 577px) and (max-width: 767px) {
  .header .headdrop .dropbtns {
    font-size: 13px !important;
  }
  .header .logoimg {
    width: 85px !important;
  }

  .header .navsec {
    gap: 0px;
  }

  .header a {
    font-size: 12px;
  }

  .header .dropbtn {
    font-size: 11px;
  }

  .header .dropdown {
    padding: 6px;
  }

  .header .rightside .connectwalletbtn {
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .header .logoimg {
    width: 85px !important;
  }
}

/* .header .dropcontent.lastmenu {
    left: unset;
    right: 0px;

} */

@media screen and (max-width: 991px) {
  .header .rightside .networksec .networkbtn {
    min-width: 30px;
    max-width: 30px;
  }
}

@media screen and (max-width: 992px) and (max-width: 1199px) {
  .header .logoimg {
    width: 85px;
  }
}
/* 
.header .dropcontent 
{
    min-width: 85vw;
    left:0px;
} */
