.innerpages {
  padding-top: 50px;
  padding-bottom: 70px;
  min-height: 70vh;
}
.predictionPage .topSection .pageTitle {
  color: var(--themecolor);
  text-transform: uppercase;
  font-weight: 700;
}
.predictionPage .topSection .valueButton {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  transition: none 0s ease 0s;
  padding: 5px 10px;
  min-width: 180px;
  border-radius: 5px;
  height: 46px;
  position: relative;
}

.predictionPage .topSection .valueButton:hover {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--linearbtn);
  transition: none 0s ease 0s;
  padding-top: 5px;
  min-width: 180px;
  padding-bottom: 5px;
  border-radius: 5px;
  height: 46px;
  position: relative;
}
.predictionPage .topSection .valueButton::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 49%;
  transform: rotate(90deg);
}

.predictionPage .topSection .valueButton::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 49%;
  transform: rotate(270deg);
}

.predictionPage .topSection .valueButton:hover {
  background: transparent !important;
  border: 1px solid var(--themecolor) !important;
  color: var(--themecolor) !important;
  transition: none 0s ease 0s !important;
}

.predictionPage .topSection .valueButton:hover::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
}

.predictionPage .topSection .valueButton:hover::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
}

.predictionPage .topSection .valueButton .logoBg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--violet_clr7);
  padding: 3px;
}
.predictionPage .topSection .valueButton .logoBg img {
  max-width: 19px;
}
.predictionPage .topSection .valueButton .amount {
  font-weight: 700;
}
.predictionPage .topSection .right .timerValue {
  color: var(--violet_clr8);
  font-size: 18px;
  font-weight: 600;
}
.predictionPage .topSection .right .timerValue span {
  font-size: 13px;
  font-weight: 400;
}
.predictionPage .topSection .right .clock {
  max-width: 42px;
}
.predictionPage .topSection .right .historyBg {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: var(--themelinear3);
  cursor: pointer;
}
.predictionPage .topSection .right .historyBg:hover {
  opacity: 0.9;
}
.predictionPage .topSection .right .historyBg img {
  max-width: 20px;
  margin-left: -2px;
}


.innerpages .datatablestyle {
  background: var(--cardbg);
  width: 100%;
}

.innerpages .datatablestyle thead {
  border-bottom: 1px solid var(--bgcolor);
  height: 45px;
}

.innerpages .datatablestyle thead tr th {
  font-size: 14px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blacktext);
  padding: 10px;

}

.innerpages .datatablestyle tbody tr {
  height: 60px;
  cursor: pointer;
}
.innerpages .datatablestylenohover tbody tr

{
  cursor: default;

}

.innerpages .datatablestyle tbody tr td {
  color: var(--blacktext);
  padding: 10px;
  font-size: 14px;
  min-width: 110px;

}

.innerpages .tablerow .tablename {
  background: var(--cardbg);
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blacktext);
  width: 120px;
  padding: 10px;
  text-align: center;
  margin-bottom: 0px;

}

.innerpages .tabstyle .tablename span {
  font-size: 10px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--themecolor);
}
.formbtn {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  /* padding-top: 20px;
    padding-bottom: 20px; */
  /* width: 100%; */
  min-width: 100%;
  border-radius: 5px;
  height: 40px;
  position: relative;
}

.formbtn:hover {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  color: var(--whitetext);
  border: 1px solid var(--linearbtn);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  /* padding-top: 5px; */
  /* width: 100%; */
  min-width: 100%;
  /* padding-bottom: 5px; */
  border-radius: 5px;
  height: 40px;
  position: relative;
}
.formbtn::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 43%;
  transform: rotate(90deg);
}

.formbtn::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 43%;
  transform: rotate(270deg);
}

.formbtn:hover {
  background: transparent !important;
  border: 1px solid var(--themecolor) !important;
  color: var(--themecolor) !important;
  transition: none 0s ease 0s !important;
}

.formbtn:hover::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
}

.formbtn:hover::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
}


.lotteryPage .datatablestyle .connectwalletbtn {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  /* padding-top: 20px;
    padding-bottom: 20px; */
  /* width: 100%; */
  min-width: 100%;
  border-radius: 5px;
  height: 40px;
  position: relative;
}

.lotteryPage .datatablestyle .connectwalletbtn:hover {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  color: var(--whitetext);
  border: 1px solid var(--linearbtn);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  /* padding-top: 5px; */
  /* width: 100%; */
  min-width: 100%;
  /* padding-bottom: 5px; */
  border-radius: 5px;
  height: 40px;
  position: relative;
}
.lotteryPage .datatablestyle .connectwalletbtn::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 43%;
  transform: rotate(90deg);
}

.lotteryPage .datatablestyle .connectwalletbtn::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 43%;
  transform: rotate(270deg);
}

.lotteryPage .datatablestyle .connectwalletbtn:hover {
  background: transparent !important;
  border: 1px solid var(--themecolor) !important;
  color: var(--themecolor) !important;
  transition: none 0s ease 0s !important;
}

.lotteryPage .datatablestyle .connectwalletbtn:hover::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
}

.lotteryPage .datatablestyle .connectwalletbtn:hover::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
}

.cardstylelottery {
  background: var(--cardbg);
  padding: 0px;
  border: 1px solid var(--violet_clr);
  border-radius: 20px;
}

.cardstylelottery .inputsec {
  background: var(--violet_clr2);
  padding: 12px;
  border-radius: 12px;
  margin-top: 8px;
}

.cardstylelottery .inputsec .inputlabel {
  color: var(--blacktext);
  font-size: 14px;
  font-weight: 600;
}

.cardstylelottery .inputsec input {
  background: none;
  border: none;
  color: var(--blacktext);
  width: 100%;
  font-size: 13px;
}
.cardstylelottery .inputsec input::placeholder {
  color: var(--blacktext);
  font-size: 13px;

}
.cardstylelottery .inputsec input:focus-visible {
  outline: none;
}

.cardstylelottery .inputsec input:focus {
  box-shadow: none;
  border: none !important;
}

.cardstylelottery .midrow {
  padding: 25px 20px;
}

.themetext
{
  color:var(--themecolor);
  font-weight: 700;
}

.iconbtnnew {
  background: var(--themecolor);
  padding: 10px;
  color: var(--whitetext);
  border:1px solid var(--themecolor);
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.iconbtnnew:hover {
  background: transparent;
  color: var(--themecolor);
  border:1px solid var(--themecolor);

}
.btncopynew
{
  background-color: transparent;
  border:none;
}
.btncopynew:hover
{
  color:var(--themecolor);
}