.canvaInner {
  background: var(--whitetext);
}
.canvaInner .header {
  padding: 20px;
  width: 100%;
}
.canvaInner .header .closeButton {
  background: transparent;
  color: var(--themecolor);
  border: none;
  outline: none;
  font-size: 20px;
}
.canvaInner .header .title {
  color: var(--themecolor);
  font-weight: 700;
  font-size: 17px;
}
.canvaInner .body .tabs {
  /* padding: 20px; */
}
.canvaInner .body .tabRow {
  padding: 20px;
}
.canvaInner .body .tabs .tabsWrap {
  background: var(--light_violet_clr7);
  border-radius: 30px;
  padding: 0px;
}
.canvaInner .body .tabs .tabHead {
  cursor: pointer;
  font-size: 15px;
  color: var(--themecolor);
  padding: 8px;
  border-radius: 30px;
}
.canvaInner .body .tabs .tabsContent .filter {
  padding: 20px;
}
.canvaInner .body .tabs .tabsContent .filterLabel {
  font-size: 14px;
  color: var(--themecolor);
  font-weight: 300;
}
.canvaInner .body .tabs .tabsContent .filter .filterHeadWrap {
  overflow-x: auto;
  padding-bottom: 10px;
}

.canvaInner .body .tabs .tabsContent .filterChoose {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--light_violet_clr4);
  cursor: pointer;
}
.canvaInner .body .tabs .tabsContent .filterTitle {
  font-weight: 300;
  color: var(--themecolor);
  font-size: 15px;
}

.canvaInner .body .tabs .tabsContent .filterContent .predictionSec {
  padding: 20px;
  padding-top: 10px;
}
.canvaInner .body .tabs .tabsContent .filterContent .desc {
  color: var(--themecolor);
  font-size: 15px;
  margin-top: 3px;
}
.canvaInner .body .tabs .tabsContent .filterContent .round {
  padding: 20px;
  border-top: 1px solid var(--light_violet_clr4);
}
.canvaInner .body .tabs .filterContent .round .title {
  font-size: 14px;
  color: var(--themecolor);
  font-weight: 300;
}
.canvaInner .body .tabs .filterContent .round .value {
  font-size: 16px;
  color: var(--blacktext);
}
.canvaInner .body .tabs .filterContent .round .liveNow {
  color: var(--dark_violet_clr3);
  font-weight: 500;
}
.canvaInner .body .tabs .tabsContent .filterContent .round.round2 {
  border-top: 1px solid var(--light_violet_clr4);
  border-bottom: 1px solid var(--light_violet_clr4);
}
.canvaInner .body .tabs .tabsContent .filterContent .round .resultValue {
  color: var(--pink_clr2);
  font-size: 20px;
}
.canvaInner .body .tabs .tabsContent .filterContent .noPrediction {
  padding: 20px;
  border-top: 1px solid var(--light_violet_clr4);
}
.canvaInner .body .tabs .tabsContent .filterContent .noPrediction .title {
  color: var(--dark_violet_clr3);
  font-weight: 500;
  text-align: center;
}
.canvaInner .body .tabs .tabsContent .filterContent .noPrediction .desc {
  color: var(--blacktext);
  text-align: center;
  font-size: 13px;
}
.canvaInner .body .tabs .pnlContent .historyDetails {
  padding: 20px;
  border-bottom: 1px solid var(--light_violet_clr4);
}

.canvaInner .body .tabs .pnlContent .title {
  color: var(--themecolor);
  font-weight: 700;
  font-size: 17px;
}
.canvaInner .body .tabs .pnlContent .donut {
  width: 120px;
  height: 120px;
  background-color: var(--whitetext);
  border-radius: 50%;
  border: 18px solid var(--pink_clr2);
}
.canvaInner .body .tabs .pnlContent .wonText {
  color: var(--dark_violet_clr4);
  font-size: 14px;
}
.canvaInner .body .tabs .pnlContent .percentage {
  color: var(--dark_violet_clr4);
  font-size: 16px;
  font-weight: 900;
  margin-top: -7px;
  margin-bottom: -6px;
}
.canvaInner .body .tabs .pnlContent .violetText {
  color: var(--dark_violet_clr4);
  font-size: 16px;
}
.canvaInner .body .tabs .pnlContent .bnbValue {
  color: var(--pink_clr2);
  font-weight: 700;
}
.canvaInner .body .tabs .pnlContent .mainText {
  color: var(--dark_violet_clr4);
  font-size: 15px;
  font-weight: 500;
}
.canvaInner .body .tabs .pnlContent .bnbDetails {
  color: var(--pink_clr2);
  font-size: 17px;
  font-weight: 500;
}
.canvaInner .body .tabs .pnlContent .aprox {
  color: var(--blacktext);
  font-size: 15px;
  font-weight: 500;
}
.canvaInner .body .tabs .pnlContent .roundCount {
  color: var(--pink_clr2);
  font-size: 18px;
  font-weight: 500;
}
.canvaInner .body .tabs .pnlContent .roundPercent {
  color: var(--blacktext);
  font-size: 15px;
  font-weight: 500;
}
 .violet_button{
  height: 40px;
  background: var(--themecolor);
  border: none;
  border-radius: 8px;
  color: var(--whitetext);
  font-weight: 600; 
  width: 100%;
}
/* media */

@media (min-width: 576px) {
  .canvaInner .body .tabs .pnlContent .donut {
    width: 150px;
    height: 150px;
    border: 23px solid var(--pink_clr2);
  }
  .canvaInner .body .tabs .pnlContent .wonText {
    font-size: 15px;
  }
  .canvaInner .body .tabs .pnlContent .percentage {
    font-size: 19px;
    margin-top: -4px;
    margin-bottom: -4px;
  }
}
