.innerpages {
  padding-top: 50px;
  padding-bottom: 70px;
  min-height: 70vh;
}
.swappage .swapcardrow {
  align-items: center;
}

.swappage .swapcardrow .cardstyle {
  background: var(--cardbg);
  padding: 0px;
  border: 1px solid var(--violet_clr);
  border-radius: 20px;
}

.swappage .cardstyle .toprow {
  display: flex;
  flex-direction: column;
  align-items: unset;
  /* justify-content: space-between; */
  padding: 20px;
  /* margin-bottom: 30px; */
  border-bottom: 1px solid var(--themecolor);
}

.swappage .cardstyle .toprow .headings {
  color: var(--themecolor);
  font-weight: 600;
  font-size: 19px;
  margin-bottom: 5px;
}

.swappage .cardstyle .toprow .desc {
  color: var(--themecolor);
  font-size: 14px;
  margin-bottom: 5px;
}

.swappage .cardstyle .toprow .iconsec {
  display: flex;
  justify-content: end;
  margin-top: 10px;
  gap: 13px;
}

.swappage .cardstyle .toprow .iconsec svg {
  fill: var(--violet_clr6);
  font-size: 24px;
  cursor: pointer;
}

.swappage .cardstyle .toprow .iconsec svg:hover {
  fill: var(--themecolor);
}

.swappage .cardstyle .midrow {
  padding: 25px 20px;
}

.swappage .cardstyle .inputsec {
  background: var(--violet_clr2);
  padding: 12px;
  border-radius: 12px;
  margin-top: 8px;
}

.swappage .cardstyle .inputsec .inputlabel {
  color: var(--blacktext);
  font-size: 14px;
  font-weight: 600;
}

.swappage .cardstyle .inputsec input {
  background: none;
  border: none;
  color: var(--blacktext);
  width: 100%;
}
.swappage .cardstyle .inputsec input::placeholder {
  color: var(--blacktext);
}
.swappage .cardstyle .inputsec input:focus-visible {
  outline: none;
}

.swappage .cardstyle .inputsec input:focus {
  box-shadow: none;
  border: none !important;
}

.swappage .cardstyle .swaparrow {
  background: var(--light_violet_clr);
  border-radius: 50px;
  height: 32px;
  width: 32px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swappage .cardstyle .swaparrow svg {
  font-size: 14px;
}

.swappage .cardstyle .arrowrow {
  justify-content: center;
  /* margin-bottom: 20px; */
}
.swappage .cardstyle .inputsecrowrecipeinet input {
  background: none;
  border: none;
  color: var(--blacktext);
  width: 80%;
}
.swappage .cardstyle .inputsecrowrecipeinet input::placeholder {
  color: var(--blacktext);
}
.swappage .cardstyle .inputsecrowrecipeinet input:focus-visible {
  outline: none;
}

.swappage .cardstyle .inputsecrowrecipeinet input:focus {
  box-shadow: none;
  border: none !important;
}
.swappage .connect_btn {
  background: var(--themecolor);
  white-space: nowrap;
  font-weight: 500;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding: 10px;
  border-radius: 11px;
  width: 100%;
}

.swappage .connect_btn:hover {
  background: var(--btnhover);
  border: 1px solid var(--btnhover);
}
.swappage .cardstyle .currencytype {
  color: var(--themecolor);
  font-weight: 600;
}
.swappage .currencybtn {
  background: var(--cardbg);
  padding: 4px;
  color: var(--themecolor);
  font-weight: 600;
  font-size: 14px;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.swappage .currencybtn:hover {
  background: var(--themecolor);
  color: var(--whitetext);
}
.swappage .currencybtn:hover .currencytype {
  color: var(--whitetext);
  font-weight: 600;
}
.swappage .currencybtn .imgbg {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.swappage .currencybtn .coinimg {
  width: 20px;
  height: 20px;
  object-fit: contain;
  background: var(--yellow_clr);
  border-radius: 50%;
}
.swappage .inputsec .rightbtnsec {
  display: flex;
  justify-content: end;
  min-width: 155px;
}
.swappage .inputsec .inputsecrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.swappage .inputsec .inputsecrow .rightmaxbtn {
  display: flex;
  align-items: center;
  gap: 10px;
}
.swappage .cardstyle .lastrow {
  padding: 25px 20px;
}
.swappage .inputsec .balancerow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.swappage .valuecardsec {
  padding: 15px;
  /* border: 1px solid var(--lightgrey); */
  border-radius: 13px;
  /* background: var(--bgcolor); */
  background: var(--violet_clr2);
}

.swappage .valuecardsec.valuecardsecnpng {
  background: transparent;
  border-radius: 0px;
  /* padding: 0px; */
  padding: 25px 20px;
}

.swappage .valuecardsec .descpara {
  color: var(--themecolor);
  font-size: 14px;
  text-align: center;
}

.swappage .valuecardsec .label {
  color: var(--blacktext);
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 600;
}

.swappage .valuecardsec .labels {
  color: var(--themecolor);
  font-size: 14px;
  text-align: center;
}

.swappage .valuecardsec .secrow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.roundiconbtn {
  background-color: var(--cardbg);
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--themecolor);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.roundiconbtn:hover {
  background-color: var(--themecolor);
  color: var(--cardbg);
}

.tooltip_button {
  background-color: transparent !important;
  border: none;
  border-radius: 0px;
  color: #fff;
  padding: 0px;
  margin-left: 10px;
}

.tooltip_style {
  padding: 16px;
  font-size: 16px;
  line-height: 130%;
  border-radius: 11px;
  max-width: 320px;
  z-index: 101;
  background: rgb(35, 40, 51);
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px,
    rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}

.swappage .cardstyle .btnsec {
  padding: 25px 20px;
}

.swappage .cardstyle .connectwalletbtn {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  /* padding-top: 20px;
    padding-bottom: 20px; */
  /* width: 100%; */
  min-width: 100%;
  border-radius: 5px;
  height: 45px;
  position: relative;
}

.swappage .cardstyle .connectwalletbtn:hover {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--linearbtn);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  /* padding-top: 5px; */
  /* width: 100%; */
  min-width: 100%;
  /* padding-bottom: 5px; */
  border-radius: 5px;
  height: 45px;
  position: relative;
}
.swappage .cardstyle .connectwalletbtn::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 43%;
  transform: rotate(90deg);
}

.swappage .cardstyle .connectwalletbtn::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 43%;
  transform: rotate(270deg);
}

.swappage .cardstyle .connectwalletbtn:hover {
  background: transparent !important;
  border: 1px solid var(--themecolor) !important;
  color: var(--themecolor) !important;
  transition: none 0s ease 0s !important;
}

.swappage .cardstyle .connectwalletbtn:hover::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
}

.swappage .cardstyle .connectwalletbtn:hover::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
}
.inputsecrowblk

{
display: block !important;
}
.proceedtext
{
  font-size: 14px;
  color:var(--blacktext);
}
.rightmaxbtn 
{
margin-left: auto;
}