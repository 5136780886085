.modalstyle .modalheader {
  border-bottom: none;
}

.modalstyle .modalbody {
  border-bottom: none;
}

.modalstyle [class="modal-content"] {
  /* background: var(--cardbg); */
  /* box-shadow: var(--themecolor) -1px 0px 14px; */
  /* border: 1px solid var(--themecolor); */
  min-width: 320px;
  max-width: 420px;
  margin: auto;
  border-radius: 30px;
  width: 100%;
  /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter {
  padding: 20px;
  border-top: 0px;
}
.modalstyle .btnstyle {
  background: var(--themecolor);
  border-radius: 7px;
  color: var(--whitetext);
  font-size: 12px;
  border: 1px solid var(--themecolor);
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
}

.modalstyle .btnstyle:hover {
  background: var(--btnhover);
  border: 1px solid var(--btnhover);
  color: #fff;
}
.modalstyle .modalheader .modaltitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
  color: var(--themecolor);
}
.modalstyle .modalheader {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  background: var(--light_linear);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.modalstyle .modalheader .closebtn {
  background: none;
  padding: 0px;
  border: none;
  color: var(--themecolor);
  cursor: pointer;
  font-size: 25px;
}
.modalstyle .modalbody {
  /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
  padding: 25px;
}
.modalstyle .modalbody .label {
  font-size: 12px;
  color: var(--whitetext);
}
.modalstyle .modalbody .walletimg {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.modalstyle .walletrow {
  width: 100px;
  height: 100px;
  padding: 10px;
}
.modalstyle .walletrow:hover .label {
  /* background: var(--themecolor); */
  color: var(--themecolor);
}
.modalstyle .walletrow:hover {
  opacity: 0.6;
  cursor: pointer;
}
.modalstyle .modalfooter .footerdesc {
  color: var(--whitetext);
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 10px;
}
.modalstyle .modalbody .innerhead {
  color: var(--themecolor);
  font-size: 15px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.modalstyle .modalbody .innerheadwhite {
  color: var(--blacktext);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 10px;
}
.modalstyle .netbtn {
  background: var(--bgcolor);
  border: none;
  border-radius: 5px;
  color: var(--whitetext);
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 5px 15px;
  width: 100%;
  margin-bottom: 12px;
  font-size: 14px;
  height: 48px;
}
.modalstyle {
  padding-right: 8px !important;
}

.modalstyle .modalbody ul {
  list-style: none;
  padding-left: 0px;
}
.modalstyle .netbtn .netimg {
  width: 30px;
  height: 30px;
}
@media screen and (max-width: 400px) {
  .modalstyle .modalbody .innerheadwhite {
    font-size: 14px;
  }

  .modalstyle [class="modal-content"] {
    min-width: 280px;
    max-width: 280px;
  }
}

@media screen and (max-width: 575px) {
  .buttonstyle_ul {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }
}
.tooltip_button {
  background-color: transparent !important;
  border: none;
  border-radius: 0px;
  color: #fff;
  padding: 0px;
  margin-left: 10px;
}

.tooltip_button.tooltip_button_blk
{
  color: #000;
  margin-top: -5px;

}
.tooltip_style {
  padding: 16px;
  font-size: 16px;
  line-height: 130%;
  border-radius: 11px;
  max-width: 320px;
  z-index: 101;
  background: rgb(35, 40, 51);
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px,
    rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}
.button_ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.button_ul li {
  margin-bottom: 10px;
}
.buttonstyle_ul {
  background-color: transparent;
  border: 1px solid var(--themecolor);
  color: var(--themecolor);
  border-radius: 11px;
  padding: 5px 13px;
  font-size: 14px;
  font-weight: 500;
  min-width: 100px;
}
.buttonstyle_ul:hover,
.buttonstyle_ul:focus,
.buttonstyle_ul:active {
  background: var(--themecolor) !important;
  border: 1px solid var(--themecolor) !important;
  color: var(--whitetext) !important;
}
.sliipage_input,
.sliipage_input:hover,
.sliipage_input:focus {
  background-color: var(--themecolor);
  color: var(--whitetext);
  border: none;
  border-radius: 11px;
  max-width: 100px;
}
.sliipage_input:focus,
.form-control:focus {
  box-shadow: none !important;
}
.percentage_text {
  display: inline-block;
  color: var(--themecolor);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-left: 5px;
}
.orangetext {
  font-size: 14px;
  color: var(--cancel);
}
.sliipage_input_sm,
.sliipage_input_sm:hover,
.sliipage_input_sm:focus {
  max-width: 60px;
}
.toggleswitch input {
  background: var(--themecolor);
  border: none;
}
.togglesec {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.togglesec .spanlabel {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--whitetext);
}

.connectwalletbtn {
  background: var(--themecolor) !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: var(--whitetext) !important;
  border: 1px solid var(--themecolor) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 11px;
  height: 48px;
}
.connectwalletbtn:hover {
  background: var(--themecolor) !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: var(--whitetext) !important;
  border: 1px solid var(--themecolor) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 11px;
  height: 48px;
  opacity: 0.65;
}

.cancelbtn {
  background: var(--cancel) !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: var(--whitetext) !important;
  border: 1px solid var(--cancel) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 11px;
  height: 48px;
}
.cancelbtn:hover {
  background: var(--cancel) !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: var(--whitetext) !important;
  border: 1px solid var(--cancel) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 11px;
  height: 48px;
  opacity: 0.65;
}

.inputsec {
    background: var(--violet_clr2);
    padding: 12px;
    border-radius: 12px;
    margin-top: 8px;
  }
  
.inputsec .inputlabel {
    color: var(--blacktext);
    font-size: 14px;
    font-weight: 600;
  }
  
.inputsec input {
    background: none;
    border: none;
    color: var(--blacktext);
    width: 100%;
  }
.inputsec input::placeholder {
    color: var(--blacktext);
  }
.inputsec input:focus-visible {
    outline: none;
  }
  .balancetext
  {
    font-size: 12px;
    text-align: right;
    margin-top: 10px;
  }
  
.inputsec input:focus {
    box-shadow: none;
    border: none !important;
  }
  
  .innerheadwhitesm
{
    color: var(--blacktext);
    font-size: 13px;
    line-height: 1.5;
}
.innerheadthemesm
{
    color: var(--themecolor);
    font-size: 12px;
    line-height: 1.5;
}
.head {
    color: var(--themecolor);
    font-size: 14px;
    font-weight: 500;
  }
  .inputlabelticket
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .inputlabelticket span
  {
    padding-left: 10px;
    padding-right: 10px;
  }



  .labeledit {
    color: var(--blacktext);
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
 .labelsedit {
    color: var(--themecolor);
    font-size: 14px;
    text-align: center;
  }

  .secrow {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  .inputticket
  {
    border-bottom:1px solid #9e9e9e !important;
    
  }
  .btnflexgap
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:10px;
  }