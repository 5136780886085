.predictionFlipCardPage {
  width: 100%;
  height: 100%;
  /* background: var(--light_linear); */
  background: var(--whitetext);
  border-radius: 20px;
}
.predictionFlipCardPage .predictSliderCard.predictSliderCardGray {
  height: 100%;
  background: var(--light_violet_clr4);
  background-size: 300% 300%;
  animation: gradient 3s ease infinite;
  border-radius: 20px;
}
.predictionFlipCardPage .cardSecondaryBg {
  position: relative;
  height: 404px;
  top: 1px;
  width: 308px;
  background-color: var(--whitetext);
  border-radius: 20px;
}
.predictionFlipCardPage .topSection {
  padding: 20px;
  background: var(--light_linear);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.predictionFlipCardPage .topSection .backButton {
  color: var(--themecolor);
}
.predictionFlipCardPage.enterDown .topSection .backButton {
  color: var(--pink_clr);
}
.predictionFlipCardPage .topSection .backButton h5 {
  font-weight: 500;
  font-size: 18px;
}
.predictionFlipCardPage .topSection .upButton {
  border: none;
  outline: none;
  background: var(--themecolor);
  border-radius: 4px;
  padding: 5px 8px;
  color: var(--whitetext);
  font-size: 15px;
}
.predictionFlipCardPage .topSection .upButton:hover {
  border: none;
  outline: none;
  background: var(--themecolor);
  border-radius: 4px;
  padding: 5px 8px;
  color: var(--whitetext);
  font-size: 15px;
  opacity: 0.8;
}
.predictionFlipCardPage .topSection .downButton {
  border: none;
  outline: none;
  background: var(--pink_clr);
  border-radius: 4px;
  padding: 5px 8px;
  color: var(--whitetext);
  font-size: 15px;
}
.predictionFlipCardPage .topSection .downButton:hover {
  border: none;
  outline: none;
  background: var(--pink_clr);
  border-radius: 4px;
  padding: 5px 8px;
  color: var(--whitetext);
  font-size: 15px;
  opacity: 0.8;
}
.predictionFlipCardPage .inputSection {
  padding: 20px;
  padding-bottom: 0px;
}
.predictionFlipCardPage .inputSection .inputTop {
  color: var(--themecolor);
}
.predictionFlipCardPage.enterDown .inputSection .inputTop {
  color: var(--pink_clr);
}
.predictionFlipCardPage .inputSection .inputTop .iconBg {
  background: var(--themecolor);
  width: 20px;
  height: 20px;
  padding: 2px;
  border-radius: 50%;
}
.predictionFlipCardPage.enterDown .inputSection .inputTop .iconBg {
  background: var(--pink_clr);
  width: 20px;
  height: 20px;
  padding: 2px;
  border-radius: 50%;
}
.predictionFlipCardPage .inputSection .inputTop .iconBg img {
  width: 12px;
}
.predictionFlipCardPage .inputSection .inputTop .inputRight p {
  font-weight: 600;
  font-size: 16px;
}
.predictionFlipCardPage .inputSection .inputField {
  width: 100%;
  margin-top: 10px;
  height: 60px;
  border: 1px solid var(--light_violet_clr2);
  background: var(--light_violet_clr);
  border-radius: 20px;
  padding: 10px;
  text-align: end;
  color: var(--themecolor);
}
.predictionFlipCardPage .inputSection .inputField:focus {
  outline: none;
}
.predictionFlipCardPage.enterDown .inputSection .inputField {
  border: 1px solid var(--pink_clr);
  background: var(--light_violet_clr);
  color: var(--pink_clr);
}
.predictionFlipCardPage.enterDown .inputSection .inputField:focus {
  outline: none !important;
}
.predictionFlipCardPage .inputSection .inputField::placeholder {
  color: var(--themecolor);
}
.predictionFlipCardPage .bottomSection {
  padding: 10px 20px;
}
.predictionFlipCardPage .bottomSection .percentage {
  margin-top: 30px;
}
.predictionFlipCardPage .bottomSection .percentage .percentValue {
  background: var(--light_white_clr);
  color: var(--light_black_clr2);
  padding: 3px 12px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
}
.predictionFlipCardPage
  .bottomSection
  .percentage
  .percentValue.activePercentValue {
  background: var(--themecolor);
  color: var(--whitetext);
  padding: 3px 12px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
}
.predictionFlipCardPage.enterDown
  .bottomSection
  .percentage
  .percentValue.activePercentValue {
  background: var(--pink_clr);
}
.predictionFlipCardPage .bottomSection .connectWallet {
  background: var(--themecolor);
  color: var(--whitetext);
  padding: 15px 20px;
  border-radius: 20px;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 17px;
  margin-top: 15px;
  width: 100%;
}
.predictionFlipCardPage.enterDown .bottomSection .connectWallet {
  background: var(--pink_clr);
}
.predictionFlipCardPage .bottomSection .connectWallet:hover {
  background: var(--themecolor);
  color: var(--whitetext);
  padding: 15px 20px;
  border-radius: 20px;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 17px;
  margin-top: 15px;
  width: 100%;
  opacity: 0.7;
}
.predictionFlipCardPage.enterDown .bottomSection .connectWallet:hover {
  background: var(--pink_clr);
}
.predictionFlipCardPage .bottomSection .desc {
  color: var(--themecolor);
  font-size: 12px;
  text-align: start;
  margin-top: 12px;
}
.predictionFlipCardPage.enterDown .bottomSection .desc {
  color: var(--pink_clr);
}
