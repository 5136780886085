.innerpages {
  padding-top: 50px;
  padding-bottom: 70px;
  min-height: 70vh;
}

.liquiditypage .swapcardrow {
  align-items: center;
}

.liquiditypage .swapcardrow .cardstyle {
  background: var(--cardbg);
  padding: 20px;
  border: 1px solid var(--violet_clr);
  border-radius: 20px;
  /* width: 450px; */
}

.liquiditypage .cardstyle .toprow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.liquiditypage .toprow .backsec {
  display: flex;
  gap: 10px;
}

.liquiditypage .cardstyle .toprow .headings {
  color: var(--themecolor);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.liquiditypage .cardstyle .toprow .desc_icon_bg {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--violet_clr2);
}
.liquiditypage .cardstyle .toprow .desc {
  color: var(--violet_clr3);
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 500;
}

.liquiditypage .cardstyle .toprow .iconsec {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.liquiditypage .cardstyle .toprow .iconsec svg {
  fill: var(--dark_violet_clr);
  font-size: 24px;
  cursor: pointer;
}

.liquiditypage .cardstyle .toprow .iconsec svg:hover {
  fill: var(--themecolor);
}

.liquiditypage .cardstyle .inputsec {
  background: var(--violet_clr2);
  padding: 12px;
  border-radius: 3px;
}
.liquiditypage .cardstyle .inputsec2 {
  position: relative;
  margin-top: 30px;
}
.liquiditypage .cardstyle .inputsec .inputlabel {
  color: var(--blacktext);
  font-size: 14px;
}

.liquiditypage .cardstyle .inputsec input {
  background: none;
  border: none;
  color: var(--blacktext);
  width: 100%;
}

.liquiditypage .cardstyle .inputsec input::placeholder {
  color: var(--blacktext);
}

.liquiditypage .cardstyle .inputsec input:focus-visible {
  outline: none;
}

.liquiditypage .cardstyle .inputsec input:focus {
  box-shadow: none;
  border: none !important;
}

.liquiditypage .cardstyle .swaparrow {
  position: absolute;
  background: var(--themecolor);
  border-radius: 50px;
  height: 40px;
  width: 40px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  top: -35px;
}

.liquiditypage.findpage .cardstyle .swaparrow {
  cursor: inherit;
}

.liquiditypage .cardstyle .swaparrow svg {
  font-size: 16px;
  color: var(--whitetext);
}

.liquiditypage .cardstyle .arrowrow {
  justify-content: center;
  /* margin-bottom: 20px; */
}

.liquiditypage .connect_btn {
  background: var(--themecolor) !important;
  white-space: nowrap;
  font-weight: 500;
  color: var(--whitetext) !important;
  border: 1px solid var(--themecolor) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding: 10px;
  border-radius: 11px;
  width: 100%;
}

.liquiditypage .connect_btn:hover {
  background: var(--themecolor) !important;
  white-space: nowrap;
  font-weight: 500;
  color: var(--whitetext) !important;
  border: 1px solid var(--themecolor) !;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding: 10px;
  border-radius: 11px;
  width: 100%;
  opacity: 0.7;
}

.liquiditypage .currencybtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--themelinear2);
  padding: 4px;
  width: 100%;
  color: var(--whitetext);
  font-size: 11px;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
  font-weight: 700;
  transition: none 0s ease 0s;
}

.liquiditypage .currencybtn:hover,
.liquiditypage .currencybtn:focus {
  background: var(--themecolor);
  color: var(--whitetext);
}

.liquiditypage .currencybtn.currencybtnnew {
  font-size: 13px;
  color: var(--blacktext);
}

.liquiditypage .currencybtn .coinimg {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.liquiditypage .inputsec .rightbtnsec {
  display: flex;
  justify-content: end;
}

.liquiditypage .inputsec .inputsecrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.liquiditypage .inputsec .inputsecrow .rightmaxbtn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.liquiditypage .inputsec .balancerow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.liquiditypage .innercontentsec {
  padding-top: 20px;
  padding-bottom: 20px;
  background: var(--violet_clr2);
}

.liquiditypage .innercontentsec .labelname {
  color: var(--blacktext);
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.liquiditypage .innercontentsec .findbtn {
  text-align: center;
  background: transparent;
  border: 1px solid var(--themecolor);
  border-radius: 12px;
  padding: 10px;
  text-decoration: none;
  color: var(--themecolor);
  font-weight: 600;
}

.liquiditypage .innercontentsec .findbtn:hover {
  background: var(--themecolor);
  color: var(--whitetext);
}

.liquiditypage .innercontentsec .findbtnsec {
  text-align: center;
}

.liquiditypage .cardstyle .toprow .backbtn {
  cursor: pointer;
}
.liquiditypage .cardstyle .toprow .backbtn a svg {
  color: var(--themecolor) !important;
}
.liquiditypage.findpage .currencybtn {
  background: transparent !important;
  width: 100%;
  justify-content: space-between;
}

.liquiditypage.findpage .currencybtn .imgbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

/* .liquiditypage.findpage .findlabelsec {
  border: 1px solid var(--themecolor);
  border-radius: 13px;
  padding: 45px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.liquiditypage.findpage .findlabelsec .findlabelcontent {
  color: var(--blacktext);
  margin-bottom: 0px;
} */
.liquiditypage.findpage .findlabelsec {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 5px;
  height: 40px;
  position: relative;
  opacity: 0.6;
}

.liquiditypage.findpage .findlabelsec ::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 43%;
  transform: rotate(90deg);
}

.liquiditypage.findpage .findlabelsec ::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 43%;
  transform: rotate(270deg);
}

.liquiditypage .desc_cardsec {
  border: 1px solid var(--themecolor);
  padding: 15px;
  background: var(--bgcolor);
  border-radius: 13px;
}

.liquiditypage .desc_cardsec .descpara {
  color: var(--desctext);
  font-size: 14px;
  margin-bottom: 0px;
}

.liquiditypage .valuecardsec {
  padding: 15px;
  /* border: 1px solid var(--lightgrey); */
  border-radius: 3px;
  background: var(--violet_clr2);
}

.liquiditypage .valuecardsec .descpara {
  color: var(--themecolor);
  font-size: 14px;
  text-align: center;
}

.liquiditypage .valuecardsec .label {
  color: var(--blacktext);
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
}

.liquiditypage .valuecardsec .labels {
  color: var(--themecolor);
  font-size: 14px;
  text-align: center;
}

.liquiditypage .valuecardsec .secrow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.liquiditypage .balancecardsec {
  background: var(--cardbg);
  padding: 15px;
  border-radius: 0px;
}

.liquiditypage .balancecardsec .head {
  color: var(--themecolor);
  font-size: 16px;
  font-weight: 500;
}

.liquiditypage .balancecardsec ul {
  list-style: none;
  padding-left: 0px;
}

.liquiditypage .balancecardsec ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
}

.liquiditypage .balancecardsec ul li p {
  margin-bottom: 0px;
  color: var(--desctext);
  font-size: 14px;
}

.liquiditypage .balancecardsec .coinimg {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.liquiditypage .balancecardsec .tooltip_button {
  background-color: transparent !important;
  border: none;
  border-radius: 0px;
  color: #fff;
  padding: 0px;
  margin-left: 5px;
  margin-top: -5px;
}

.liquiditypage .balancecardsec .tooltip_style {
  padding: 16px;
  font-size: 16px;
  line-height: 130%;
  border-radius: 11px;
  max-width: 320px;
  z-index: 101;
  background: rgb(35, 40, 51);
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px,
    rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}

.liquiditypage .desccardsec {
  background: var(--cardbg);
  padding: 15px;
  border-radius: 0px;
}

.liquiditypage .desccardsec .head {
  color: var(--themecolor);
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
}

.liquiditypage .desccardsec .coinimg {
  width: 15px;
  height: 15px;
}

.liquiditypage .poolaccordion .coinimg {
  width: 20px;
  height: 20px;
  object-fit: contain;
  /* filter: invert(1); */
}

.liquiditypage .poolaccordion_ul .labelhead {
  color: var(--whitetext);
  font-size: 16px;
  font-weight: 600;
}

.liquiditypage .poolaccordion [class="accordion-header"] button {
  background: var(--bgcolor);
  border: none;
  box-shadow: none;
}

.liquiditypage .poolaccordion [class="accordion-item"] {
  border: none;
  box-shadow: none;
}

.liquiditypage .poolaccordion [class="accordion-item"]:hover {
  box-shadow: none;
}

.liquiditypage .poolaccordion [class="accordion-header"] .labelhead {
  color: var(--whitetext);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.liquiditypage .poolaccordion [class="accordion-header"] .balance {
  color: var(--themecolor);
  font-size: 14px;
  font-weight: 500;
}

.liquiditypage .poolaccordion .poolaccordion_ul {
  padding-left: 0px;
  list-style: none;
}

.liquiditypage .poolaccordion .poolaccordion_ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.liquiditypage .poolaccordion .poolaccordion_ul li .labelname {
  color: var(--desctext);
  font-size: 14px;
  margin-bottom: 5px;
}

.liquiditypage .poolaccordion [class="accordion-body"] {
  background: var(--bgcolor);
}

.liquiditypage .poolaccordion .walletbtn {
  background: var(--themecolor);
  white-space: nowrap;
  font-weight: 500;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding: 10px;
  border-radius: 11px;
  width: 100%;
}

.liquiditypage .poolaccordion .walletbtn:hover {
  background: var(--btnhover);
  border: 1px solid var(--btnhover);
}

.liquiditypage .poolaccordion [class="accordion"] {
  margin-bottom: 20px;
}

.liquiditypage .poolaccordion .transbtn {
  background: transparent;
  white-space: nowrap;
  font-weight: 500;
  color: var(--themecolor);
  border: none;
  box-shadow: none;
  padding: 10px;

  width: 100%;
}

.liquiditypage .poolaccordion .transbtn:hover {
  color: var(--whitetext);
}

.liquiditypage .poolaccordion button:not(.collapsed)::after {
  filter: invert(1);
}

.liquiditypage .poolaccordion button:focus {
  box-shadow: none;
}

.liquiditypage.removepage .pricesec .pricecard {
  background: var(--violet_clr2);
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.liquiditypage.removepage .pricesec .pricecard ul {
  padding-left: 0px;
  list-style: none;
}

.liquiditypage.removepage .pricesec .pricecard li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.liquiditypage.removepage .pricesec .pricename {
  color: var(--themecolor);
  font-size: 14px;
}

.liquiditypage.removepage .pricesec .pricecard .coinimg {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.liquiditypage.removepage .pricesec .pricecard li p {
  color: var(--themecolor);
  font-size: 14px;
  margin-bottom: 0px;
}

.liquiditypage.removepage .btnsec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.liquiditypage.removepage .tabrow .labelname {
  color: var(--themecolor);
  font-size: 14px;
  margin-bottom: 0px;
}

.liquiditypage.removepage .tabrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.liquiditypage.removepage .tabrow .tabbtn {
  background: var(--themecolor);
  color: var(--whitetext);
  padding: 5px 10px;
  font-size: 14px;
}

.liquiditypage.removepage .tabrow .tabbtn:hover {
  background: var(--violet_clr2);
  color: var(--themecolor);
}

.liquiditypage.removepage .rangesec {
  border: 1px solid var(--lighttext);
  padding: 10px;
  border-radius: 13px;
  margin-bottom: 10px;
}

.liquiditypage.removepage .rangesec .rangeshow {
  color: var(--themecolor);
  font-size: 20px;
  font-weight: 600;
}

.liquiditypage.removepage .rangesec .btngrpsec .btnstyle {
  background: transparent;
  color: var(--themecolor);
  border: 1px solid var(--themecolor);
  font-size: 12px;
  width: 100px;
  padding: 5px;
}

.liquiditypage.removepage .rangesec .btngrpsec .btnstyle:hover {
  background: var(--themecolor);
  color: var(--whitetext);
}

.liquiditypage.removepage .rangesec .btngrpsec {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.liquiditypage.removepage .rangesec .rangeinput[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
  height: 16px;
  margin-bottom: 20px;
}

.liquiditypage.removepage
  .rangesec
  .rangeinput[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: var(--bgcolor);
  border-radius: 16px;
}

.liquiditypage.removepage
  .rangesec
  .rangeinput[type="range"]::-moz-range-track {
  height: 15px;
  background: var(--bgcolor);
  border-radius: 16px;
}

.liquiditypage.removepage
  .rangesec
  .rangeinput[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  background-color: var(--whitetext);
  border-radius: 50%;
  border: 1px solid var(--whitetext);
  box-shadow: -407px 0 0 400px var(--themecolor);
  margin-top: -3px;
}

/* Thumb: Firefox */
.liquiditypage.removepage
  .rangesec
  .rangeinput[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid var(--themecolor);
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px var(--themecolor);
}

.linktheme {
  color: var(--themecolor);
}
.linktheme:hover {
  color: #000;
}

.liquiditypage .cardstyle .connectwalletbtn {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--themecolor);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  /* padding-top: 20px;
    padding-bottom: 20px; */
  /* width: 100%; */
  min-width: 100%;
  border-radius: 5px;
  height: 45px;
  position: relative;
}

.liquiditypage .cardstyle .connectwalletbtn:hover {
  background: var(--linearbtn);
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--whitetext);
  border: 1px solid var(--linearbtn);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; */
  transition: none 0s ease 0s;
  /* padding-top: 5px; */
  /* width: 100%; */
  min-width: 100%;
  /* padding-bottom: 5px; */
  border-radius: 5px;
  height: 45px;
  position: relative;
}
.liquiditypage .cardstyle .connectwalletbtn::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  left: -5px;
  top: 43%;
  transform: rotate(90deg);
}

.liquiditypage .cardstyle .connectwalletbtn::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 5px;
  border-radius: 150px 150px 0 0;
  background: var(--whitetext);
  right: -5px;
  top: 43%;
  transform: rotate(270deg);
}

.liquiditypage .cardstyle .connectwalletbtn:hover {
  background: transparent !important;
  border: 1px solid var(--themecolor) !important;
  color: var(--themecolor) !important;
  transition: none 0s ease 0s !important;
}

.liquiditypage .cardstyle .connectwalletbtn:hover::after {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  right: -3px;
}

.liquiditypage .cardstyle .connectwalletbtn:hover::before {
  border: 1px solid var(--themecolor);
  border-bottom: none;
  left: -3px;
}
