@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
@import "rsuite/dist/rsuite.css";

:root {
  --themecolor: #7645d9;
  --themelinear: linear-gradient(180deg, #6320eb 0%, #7343d6 100%);
  --linearbtn: linear-gradient(180deg, #6320eb 0%, #7343d6 100%);

  --themelite: #f2edfb;
  --greytext1: #373737;
  --bgcolor: #1c2029;
  --greytext: #3d4e6b;

  --lighttext: #9d9d9d;
  --whitetext: #ffffff;
  --desctext: #9d9999;
  /* --cardbg: #232833; */
  --cardbg: #ffffff;
  --labelbg: #1d242f;
  --blacktext: #000000;
  --progressbar: #313846;
  --redtext: #fd2b2b;
  --lightgrey: #859bc0;
  --btnhover: #77d1ff;
  --themehover: #fe80c926;
  --cancel: #da1524;
  --acc_clr: #232832;

  --dark_violet_clr: #692ee1;
  --dark_violet_clr2: #280d5f;
  --dark_violet_clr3: #6523ea;
  --dark_violet_clr4: #6728e6;
  --violet_clr: #c1a5fc;
  --violet_clr2: #eae0ff;
  --violet_clr3: #6e4fad;
  --violet_clr4: #6321ea;
  --violet_clr5: #692ee2;
  --violet_clr6: #a27cf2;
  --violet_clr7: #9561fd;
  --violet_clr8: #6626e6;
  --violet_clr9: #d1baff;
  --light_violet_clr: #eeeaf4;
  --light_violet_clr2: #d7caec;
  --light_violet_clr2: #9d85cd;
  --light_violet_clr3: #7a6eaa;
  --light_violet_clr4: #e7e3eb;
  --light_violet_Clr5: #e8dcff;
  --light_violet_clr6: #ab97d3;
  --light_violet_clr7: #eee6ff;

  --pink_clr: #ed4b9e;
  --pink_clr2: #ef4d9f;
  --yellow_clr: #f0b90b;
  --color_gold: #ffc700;
  --green_clr: #1fc7d4;
  --green_clr2: #31d0aa;
  --light_blue_clr: #eaf8ff;
  --light_black_clr: #666666;
  --light_black_clr2: #bdc2c4;
  --light_white_clr: #e9eaeb;

  --grayClr2: #ddd;

  --light_linear: linear-gradient(94.57deg, #f2ecf2 0%, #e8f2f6 100%);
  --themelinear2: linear-gradient(90deg, #6424e8 0%, #713fd7 100%);
  --themelinear3: linear-gradient(180deg, #6421ea 0%, #7241d6 100%);
  --themelinear4: linear-gradient(180deg, #53dee9 0%, #7645d9 100%);
  --themelinear5: linear-gradient(180deg, #ffffff 0%, #e9e9e9 100%);
  --themelinear6: linear-gradient(180deg, #6423e9 0%, #381483 100%);
  --themelinear7: linear-gradient(100.72deg, #313d5c 0%, #3d2a54 100%);
}

body {
  margin: 0;
  /* font-family: 'Space Grotesk', sans-serif !important; */
  font-family: "Lexend", sans-serif !important;
  background: var(--whitetext) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#tablestyle1 .asrt-table-body {
  max-height: 250px;
}

#launchpadtable .asrt-table-head {
  display: block;
  background: var(--cardbg);
  margin-bottom: -15px;
  padding: 20px;
}

#launchpadtable .asrt-table-head .float-right.text-right {
  width: 100%;
  height: 35px;
}

#launchpadtable .asrt-table-head .float-right.text-right .table_filter {
  width: 100% !important;
}

#launchpadtable .asrt-table-head .float-right.text-right .table_filter input {
  width: 100% !important;
  background: var(--bgcolor);
  border: none;
  height: 35px;
  color: var(--whitetext);
  background-image: url("./assests/images/searchicon.png");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: top 10px right 10px;
}

#launchpadtable
  .asrt-table-head
  .float-right.text-right
  .table_filter
  input::placeholder {
  color: var(--whitetext);
}

#launchpadtable
  .asrt-table-head
  .float-right.text-right
  .table_filter
  input:focus {
  box-shadow: none;
  background-image: none;
}

#launchpadtable .asrt-table-body {
  max-height: 650px;
}

#launchpadtable::-webkit-scrollbar-track,
#tablestyle1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#launchpadtable::-webkit-scrollbar,
#tablestyle1::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
  height: 8px;
  border-radius: 20px;
}

#launchpadtable::-webkit-scrollbar-thumb,
#tablestyle1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--themecolor);
}

.as-react-table {
  background: var(--cardbg);
}

#tablestyle1 .asrt-table-foot,
#launchpadtable .asrt-table-foot {
  display: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #000;
}
::-webkit-scrollbar {
  width: 8px;

  background-color: #000;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffff;
}

.tooltips {
  background: var(--cardbg);
}
@media screen and (max-width: 767px) {
  #ingwidth_5 {
    padding: 20px 30px !important;
  }
}

@media screen and (min-width: 768px) {
  #ingwidth_5 {
    padding: 25px 35px !important;
  }
}

.btn_trans_icon_table {
  background-color: transparent;
  border: none;
  color: var(--themecolor);
}
.btn_trans_icon_table:hover {
  color: var(--whitetext);
}
.btn_trans_status_table {
  background-color: var(--themecolor);
  color: var(--whitetext);
  border: none;
  padding: 8px 10px;
  line-height: 1;
  pointer-events: none;
}

.btn_trans_status_table.disabled_btn_apply {
  background-color: var(--bgcolor);
  color: var(--whitetext);
  border: none;
  padding: 8px 10px;
  line-height: 1;
  pointer-events: none;
  opacity: 0.5;
  /* cursor: none; */
}
.tabstyle .nav-tabs {
  border: none;
}

/* start of prediction swiper  */

.prediction_swiper .swiper-slide {
  width: 310px !important;
  height: 410px !important;
  /* background: red !important; */
}
.flipActive {
  transform: rotateY(180deg);
}
.predict_range .range-slider {
  height: 3px;
  border-radius: 5px;
}
.predict_range .range-slider__thumb {
  width: 15px !important;
  height: 15px !important;
  background: var(--themecolor) !important;
}
.enterdown .predict_range .range-slider__thumb {
  background: var(--pink_clr) !important;
}
.predict_range .range-slider__range {
  background-color: var(--themecolor) !important  ;
  left: 0% !important;
  border-radius: 5px;
}
.enterdown .predict_range .range-slider__range {
  background-color: var(--pink_clr) !important  ;
}

.predict_range .range-slider__thumb[data-lower] {
  display: none !important;
}
/* .swiper-button-prev {
  position: absolute !important;
  top: -20% !important;
  left: 40% !important;
}
.swiper-button-next {
  position: absolute !important;
  top: -20% !important;
  right: 40% !important;
}
.swiper.swiper-horizontal {
  overflow-y: visible !important;
  overflow-x: hidden !important;
} */
/* end of prediction swiper */

/* start of offcanvas style */
.canvaInner .offcanvas-header {
  padding: 0px;
}
.canvaInner .offcanvas-body {
  padding: 0px;
}
.canvaInner.historyCanva .tabHeadActive {
  background: var(--violet_clr9);
}
.canvaInner.historyCanva .filter .filterChooseActive {
  background: var(--light_blue_clr);
  border: 4px solid var(--green_clr2);
}
.canvaInner.historyCanva ::-webkit-scrollbar {
  height: 4px;
}
/* end of offcanvas style */

/* input:checked ~ .checkmark {
  background-color: red !important;
}
input[type="checkbox"] {
  width: 20px !important;
  height: 20px !important;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
}

.checkmark:after {
  content: "";
  position: absolute;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
} */

.mdl_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.mdl_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.mdl_container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.mdl_container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.mdl_container input:checked ~ .checkmark {
  background-color: var(--themecolor);
}

/* Create the checkmark/indicator (hidden when not checked) */
.mdl_container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.mdl_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.mdl_container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom_AutoInput input {
  width: 37px !important;
  height: 37px !important;
  border-bottom: 1px solid #9e9e9e !important;
}
.custom_AutoInput input:focus {
  border-bottom: 1px solid #9e9e9e !important;
}
.custom_AutoInput span {
  display: none;
}
.custom_AutoInput div {
  display: flex;
  gap: 20px;
  justify-content: center;
}

@media (max-width: 479px) {
  .custom_AutoInput input {
    width: 25px !important;
    height: 25px !important;
    font-size: 10px;
  }
  .custom_AutoInput div {
    gap: 10px;
  }
}
/* start of custom range slider */

.custom_range_slider input[type="range"] {
  -webkit-appearance: none !important;
  background: var(--grayClr2);
  height: 7px;
  z-index: 1;
}
.down_custom_range_slider input[type="range"] {
  -webkit-appearance: none !important;
  background: var(--grayClr2);
  height: 7px;
  z-index: 1;
}

.custom_range_slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  background: var(--themecolor);
  height: 13px;
  width: 13px;
  border-radius: 50%;
}
.down_custom_range_slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  background: var(--pink_clr);
  height: 13px;
  width: 13px;
  border-radius: 50%;
}
.custom_range_slider input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none !important;
  background: var(--themecolor);
  height: 13px;
  width: 13px;
  border-radius: 50%;
}
.down_custom_range_slider input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none !important;
  background: var(--pink_clr);
  height: 13px;
  width: 13px;
  border-radius: 50%;
}
.custom_range_slider input[type="range"]::-ms-thumb {
  -webkit-appearance: none !important;
  background: var(--themecolor);
  height: 13px;
  width: 13px;
  border-radius: 50%;
}
/* .down_custom_range_slider input[type="range"]::-ms-thumb {
  -webkit-appeainput[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #002200;
    background: #205928;
    border-radius: 1px;
    border: 1px solid #18d501;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 3px 3px 3px #00aa00;
    border: 2px solid #83e584;
    height: 23px;
    width: 23px;
    border-radius: 23px;
    background: #439643;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
} */
.custom_range_slider .range-slider__tooltip__label {
  margin-top: 8px !important;
  color: var(--themecolor) !important;
  font-weight: 600;
  display: none;
}
.down_custom_range_slider .range-slider__tooltip__label {
  margin-top: 8px !important;
  color: var(--pink_clr) !important;
  display: none;
  font-weight: 600;
}
/* .custom_range_slider .up_progress_fill {
  position: absolute;
  height: 7px;
  background: var(--themecolor);
  z-index: 2;
  border-radius: 10px;
}
.down_custom_range_slider .down_progress_fill {
  position: absolute;
  height: 7px;
  background: var(--pink_clr);
  z-index: 2;
  border-radius: 10px;
} */

/* end of custom range slider */

/* start of table css */

.w_130 {
  min-width: 130px !important;
}
.w_150 {
  min-width: 150px !important;
}
.w_200 {
  min-width: 200px !important;
}

/* end of table css */

/* start of swiper css */

.swiper-button-disabled {
  opacity: 0.3;
}
/* end of swiper css */
