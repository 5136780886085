.modalstyle .modalheader {
  border-bottom: none;
}

.modalstyle .modalbody {
  border-bottom: none;
}

.modalstyle [class="modal-content"] {
  background: var(--cardbg);
  /* box-shadow: var(--themecolor) -1px 0px 14px; */
  /* border: 1px solid var(--themecolor); */
  min-width: 320px;
  max-width: 420px;
  margin: auto;
  border-radius: 30px;
  width: 100%;
  /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter {
  padding: 20px;
  border-top: 0px;
}
.modalstyle .btnstyle {
  background: var(--themecolor);
  border-radius: 7px;
  color: var(--whitetext);
  font-size: 12px;
  border: 1px solid var(--themecolor);
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
}

.modalstyle .btnstyle:hover {
  background: var(--btnhover);
  border: 1px solid var(--btnhover);
  color: #fff;
}
.modalstyle .modalheader .modaltitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
  color: var(--themecolor);
}
.modalstyle .modalheader {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  background: var(--light_linear);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.modalstyle .modalheader .closebtn {
  background: none;
  padding: 0px;
  border: none;
  color: var(--themecolor);
  cursor: pointer;
  font-size: 25px;
}
.modalstyle .modalbody {
  /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
  padding: 25px;
}

.modalstyle {
  padding-right: 8px !important;
}
.modalstyle .headlabel {
  font-size: 16px;
  color: var(--themecolor);
  text-align: center;
  font-weight: 600;
}
.modalstyle .vallabel {
  font-size: 14px;
  color: var(--blacktext);
  text-align: center;
  font-weight: 500;
}
.modalstyle .declabel {
  font-size: 14px;
  color: var(--themecolor);
  text-align: center;
  font-weight: 600;
}
@media screen and (max-width: 400px) {
  .modalstyle [class="modal-content"] {
    min-width: 280px;
    max-width: 280px;
  }
}
