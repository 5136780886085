.modalstyle .modalheader {
  border-bottom: none;
}

.modalstyle .modalbody {
  border-bottom: none;
}

.modalstyle [class="modal-content"] {
  /* background: var(--cardbg); */
  /* box-shadow: var(--themecolor) -1px 0px 14px; */
  /* border: 1px solid var(--themecolor); */
  min-width: 320px;
  max-width: 420px;
  margin: auto;
  border-radius: 30px;
  width: 100%;
  /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter {
  padding: 20px;
  border-top: 0px;
}
.modalstyle .btnstyle {
  background: var(--themecolor);
  border-radius: 7px;
  color: var(--whitetext);
  font-size: 12px;
  border: 1px solid var(--themecolor);
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
}

.modalstyle .btnstyle:hover {
  background: var(--btnhover);
  border: 1px solid var(--btnhover);
  color: #fff;
}
.modalstyle .modalheader .modaltitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
  color: var(--themecolor);
}
.modalstyle .modalheader {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  background: var(--light_linear);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.modalstyle .modalheader .closebtn {
  background: none;
  padding: 0px;
  border: none;
  color: var(--themecolor);
  font-size: 20px;
  cursor: pointer;
}
.modalstyle .modalbody {
  /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
  padding: 25px;
}
.modalstyle .modalbody .label {
  font-size: 12px;
  color: var(--whitetext);
}
.modalstyle .modalbody .walletimg {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.modalstyle .walletrow {
  width: 100px;
  height: 100px;
  padding: 10px;
}
.modalstyle .walletrow:hover .label {
  /* background: var(--themecolor); */
  color: var(--themecolor);
}
.modalstyle .walletrow:hover {
  opacity: 0.6;
  cursor: pointer;
}
.modalstyle .modalfooter .footerdesc {
  color: var(--whitetext);
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 10px;
}
.modalstyle .modalbody .innerhead {
  color: var(--themecolor);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.modalstyle .modalbody .innerheadwhite {
  color: var(--themecolor);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 10px;
}

.modalstyle {
  padding-right: 8px !important;
}

@media screen and (max-width: 400px) {
  .modalstyle .modalbody .innerheadwhite {
    font-size: 14px;
  }

  .modalstyle [class="modal-content"] {
    min-width: 280px;
    max-width: 280px;
  }
}

.orangetext {
  font-size: 14px;
  color: #f3841e;
}

.pinkBox {
  display: flex;
  padding: 16px;
  border-radius: 11px;
  /* border: 1px solid var(--light_violet_clr2); */
  margin-bottom: 24px;
  background: var(--violet_clr2);
  color: var(--themecolor);
  font-size: 15px;
}
.pinktriangle {
  fill: var(--themecolor);
  font-size: 20px;
  min-width: 30px;
}
.checkboxstyle {
  background-color: var(--themecolor) !important;
  border-color: var(--themecolor) !important;
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px !important;
  border-radius: 8px;
}
.connectwalletbtn {
  background: var(--themecolor) !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: var(--whitetext) !important;
  border: 1px solid var(--themecolor) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 11px;
  height: 48px;
}
.connectwalletbtn:hover {
  background: var(--themecolor) !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: var(--whitetext) !important;
  border: 1px solid var(--themecolor) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 11px;
  height: 48px;
  opacity: 0.7;
}

.cancelbtn {
  background: var(--cancel) !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: var(--whitetext) !important;
  border: 1px solid var(--cancel) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 11px;
  height: 48px;
}
.cancelbtn:hover,
.cancelbtn:active {
  background: var(--cancel) !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: var(--whitetext) !important;
  border: 1px solid var(--cancel) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  transition: none 0s ease 0s;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-radius: 11px;
  height: 48px;
  opacity: 0.7;
}
.checkboxstyle:focus {
  box-shadow: 0 0 0.25rem rgb(254 128 201 / 42%) !important;
}
